<template>
  <a-row class="user-lists">
    <div class="body">
      <a-row><div class="title">Candidates</div></a-row>
      <a-row class="filter">
        <div v-if="!showResendButton"></div>
        <a-button type="primary" v-if="showResendButton" @click="resendCredentials()" :loading="loading">
          Resend Login
          <template #icon><SendOutlined /></template>
        </a-button>
        <a-input-search v-model:value="searchString" placeholder="Search here ..." @search="onSearch" />
      </a-row>
      <div class="users">
        <a-table :row-selection="rowSelection" :columns="columns" :data-source="candidates" class="ant-table-striped"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          rowKey="_id" :pagination=false
        >
          <template #nameSection="{ record }">
            <router-link :to="'/client/candidates/edit/'+record._id+loginAs">
            <div class="profileimg">
              <img :src="record.profile_image ? record.profile_image : 'https://ui-avatars.com/api/?background=FFC788&name=' + record.first_name + '&rounded=true&color=ffffff'" />
              <span class="name">{{ record.first_name }} {{ record.last_name }}</span>
            </div>
            </router-link>
          </template>
          <template #certificate_access="{ text: certificate_access }">
            <span>
              <a-tag :color="certificate_access ? 'green' : 'red'"> {{ certificate_access ? 'Enabled' : 'Disabled' }} </a-tag>
            </span>
          </template>
          <template #status="{ text: status }">
            <span>
              <a-tag :color="status ? 'green' : 'red'"> {{ status ? 'Active' : 'Pending' }} </a-tag>
            </span>
          </template>
          <template #action="{ record }">
            <div class="actionblock">
              <div class="action edit" @click="editCandidate(record)">
                <a-tooltip>
                  <template #title>Edit Candidate</template>
                  <EditOutlined />
                </a-tooltip>
              </div>
              <div class="action delete"  @click="confirmDeleteCandidate(record, index)">
                <a-tooltip>
                  <template #title>Delete Candidate</template>
                  <DeleteOutlined />
                </a-tooltip>
              </div>
              <div class="action login-as" @click="showLoginConfirm(record)">
                <a-tooltip>
                  <template #title>Login As Candidate</template>
                  <LoginOutlined />
                </a-tooltip>
              </div>
              <div class="action re-ge-certi" v-if="loginAs!=''" @click="regenerateCertificateConfirm(record)">
                <a-tooltip>
                  <template #title>Regenerate Missing Certificate</template>
                  <RedoOutlined />
                </a-tooltip>
              </div>
            </div>
          </template>
        </a-table>
        <a-pagination v-model:current="currentPage" :total="totalPage" v-model:pageSize="defaultPageSize" show-size-changer @change="onPageChange" @showSizeChange="onPageChange" />
      </div>
    </div>
  </a-row>
</template>

<script lang="ts">
import { defineComponent, ref, computed, unref, createVNode, onMounted } from 'vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { notification, Modal } from 'ant-design-vue'
import { CloseCircleOutlined, SendOutlined, LoginOutlined, RedoOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
import IProfile from '../../../interface/profile'

import profileService from '../../../services/profile'
import commonServices from '../../../services/common'
import router from '../../../router'
import certficateService from '@/services/certficate'

type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    SendOutlined,
    LoginOutlined,
    RedoOutlined,
    DeleteOutlined,
    EditOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    const candidates = ref<Array<any>>([])
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const defaultPageSize = ref<number>(10)
    const totalPage = ref<number>(1)
    const currentPage = ref<number>(1)
    const pagination = ref<any>()
    const columns = [
      {
        title: 'Name',
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'nameSection' }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        scopedSlots: { customRender: 'email' }
      },
      {
        title: 'Mobile No',
        dataIndex: 'phone',
        align: 'center'
      },
      {
        title: 'Enrolled Course',
        dataIndex: 'total_course',
        align: 'center'
      },
      {
        title: 'Certificate',
        dataIndex: 'certificate_access',
        slots: { customRender: 'certificate_access' },
        align: 'center'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        slots: { customRender: 'status' },
        align: 'center'
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const selectedRowKeys = ref<Key[]>([])
    const showResendButton = ref<boolean>(false)
    const searchString = ref<string>('')
    const candidateData = ref<Array<IProfile>>([])
    const getCandidates = async () => {
      const params = { pageSize: defaultPageSize.value, page: currentPage.value, search: searchString.value }
      const clientId = commonServices.getClientId()
      const responce = await profileService.getCandidates(clientId, params)
      candidates.value = responce.data.candidates
      candidateData.value = responce.data.candidates
      if (responce.data.pagination) {
        pagination.value = responce.data.pagination
        totalPage.value = responce.data.pagination.totalRecord
      }
    }
    const onPageChange = (current: number, pageSize: number) => {
      currentPage.value = current
      defaultPageSize.value = pageSize
      getCandidates()
    }
    const onSearch = () => {
      getCandidates()
      // candidates.value = searchService.search(candidateData.value, searchValue)
    }
    const showLoginConfirm = async (profile) => {
      Modal.confirm({
        title: 'Are you sure to login as candidate?',
        icon: createVNode(LoginOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to login as candidate?. This will login the user in new window.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
          window.open('/candidate/course?loginAs=candidate', '_blank')
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const regenerateCertificate = async (profile) => {
      try {
        await certficateService.generateMissingCertficate(profile._id)
        await notify('Success', 'Certificate generated successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const regenerateCertificateConfirm = async (profile) => {
      Modal.confirm({
        title: 'Regenerate mising certificates?',
        icon: createVNode(RedoOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure to regenerate mising certificates?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          regenerateCertificate(profile)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const editCandidate = async (candidate) => {
      router.push(`/client/candidates/edit/${candidate._id}${loginAs.value}`)
    }
    const deleteCandidate = async (profile, index) => {
      try {
        candidates.value.splice(index, 1)
        const clientId = commonServices.getClientId()
        const subscriptionId = commonServices.getCurrentSubscription()._id
        const subscription = await profileService.deleteCandidate({ profileId: profile._id, clientId: clientId, subscriptionId: subscriptionId })
        localStorage.subscription = JSON.stringify(subscription.data)
        await notify('Success', 'Client deleted successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCandidate = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await deleteCandidate(profile, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const resendCredentials = async () => {
      try {
        loading.value = true
        await profileService.resendCredentials({ profileIds: selectedRowKeys.value })
        showResendButton.value = false
        loading.value = false
        selectedRowKeys.value = []
        await notify('Success', 'Login credentials send successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    // select table rows checkbox
    const onSelectChange = (changableRowKeys: Key[]) => {
      console.log('selectedRowKeys changed: ', changableRowKeys)
      selectedRowKeys.value = changableRowKeys
      if (changableRowKeys.length > 0) {
        showResendButton.value = true
      } else {
        showResendButton.value = false
      }
    }
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: 'Select All Data',
            onSelect: (changableRowKeys: Key[]) => {
              console.log('changableRowKeys', changableRowKeys)
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'odd',
            text: 'Select Odd Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false
                }
                return true
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'even',
            text: 'Select Even Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true
                }
                return false
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          }
        ]
      }
    })
    onMounted(() => {
      getCandidates()
    })
    return {
      columns,
      onSearch,
      searchString,
      editCandidate,
      selectedRowKeys,
      rowSelection,
      confirmDeleteCandidate,
      notify,
      candidates,
      showResendButton,
      resendCredentials,
      loading,
      loginAs,
      showLoginConfirm,
      regenerateCertificate,
      regenerateCertificateConfirm,
      defaultPageSize,
      totalPage,
      currentPage,
      onPageChange
    }
  }
})
</script>

<style lang="scss">
.user-lists {
  padding: 10px;
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    .title {
      color: #707070;
      float: left;
      font-size: 20px;
      text-transform: capitalize;
      font-family: "TT Norms Pro Medium";
    }
    .filter {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 0 0;
      .noofentries {
        display: flex;
        align-items: center;
        .ant-select {
          margin: 0 5px;
        }
      }
      .ant-input-affix-wrapper {
        width: 200px;
      }
      .ant-select,
      .ant-btn {
        float: left;
      }
      .ant-btn {
        background: #38B6FF;
        border-color: #38B6FF;
        border-radius: 20px;
        font-family: "TT Norms Pro Medium";
      }
    }
    .users {
      margin-top: 20px;
      font-family: "TT Norms Pro Medium";
      .profileimg {
        display: flex;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 20px;
          transform: scale(0.8);
          border-radius: 50%;
          margin: 0 5px 0 0;
        }
        span {
          color: #727cf5;
          text-transform: capitalize;
        }
      }
      .actionblock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 85%;
        margin: 0 auto;
        .action {
          width: 28px;
          height: 28px;
          color: #38B6FF;
          border-radius: 50%;
          border: 1px solid #38B6FF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .action:hover {
          background: #38B6FF;
          color: #ffffff;
        }
      }
      .ant-table {
        color: #646464;
        .ant-table-thead {
          tr {
            th {
              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              &:nth-child(3) {
                font-family: "TT Norms Pro Regular";
              }
            }
          }
        }
      }
      .ant-pagination {
        margin-top: 20px;
        float: right;
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          border-radius: 50%;
          border: 1px solid #38B6FF;
          color: #38B6FF;
        }
        .ant-pagination-item {
          margin-right: 0px;
          border: none;
          font-family: "TT Norms Pro Regular";
        }
        .ant-pagination-item a:hover {
          background: #38B6FF;
          color: #fff;
          border-radius: 50%;
        }
        li:nth-child(2) {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        .ant-pagination-next {
          margin-left: 8px;
        }
        .ant-pagination-item-active {
          a {
            background: #38B6FF;
            color: #fff;
            border-radius: 50%;
          }
        }
      }
      .totalentries {
        font-family: "TT Norms Pro Regular";
        text-align: left;
        margin: -40px 0 0 0;
        color: #646464;
        p {
          margin: 0;
        }
      }
    }
  }
}

</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
